<template>
  <div class="Navbar">
    <div class="title">
      空天地一体化生态碳汇智能监测平台
    </div>
    <div class="bg"></div>
    <!-- <div class="right-bg"></div> -->
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.Navbar{
  width: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
}
.title{
  width: 100%;
  height: 24px;
  font-size: 42px;
  font-family: PangMenZhengDao;
  color: #D1FEE9;
 line-height: 24px;
  letter-spacing: 7px;
  margin-top: 19px;
  z-index: 9;
}
.bg{
  width: 1920px;
  height: 100px;
  background-image: url("../../assets/Navbar/编组 3@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
}

</style>